import React from 'react';

function websiteInfo() {
    
    
    return (
        <>
        
        <div className="website-info">
            
            
           
            <div className="info-card-1 website-info-cards"></div>
            <div className="info-card-2 website-info-cards"></div>
            <div className="info-card-3 website-info-cards"></div>
            <div className="info-card-4 website-info-cards"></div>
            <div className="info-card-5 website-info-cards"></div>



        </div>
    </>)
}

export default websiteInfo;
// html5, css3, cssGrid, react-bootstrap, react, axious, aos
import React from 'react'

function Summery() {
    return (
       
        

        
            <div className="summery-text" data-aos="fade-in" >
                <h3>Why computers</h3>
                <p><b>I </b>always loved computers, from a young age, as soon as I modified my first HTML page I was hooked, I wanted to understand how computers worked  on every possible level. The complexity in the architecture of a computer made me want to learn more about it's inner workings, there was just something about it that I could never let go of. 
                </p>
                
                <h3>Why programming</h3>
                <p><b>I'm </b> a beauty behind the madness kind of a person, As a child, I wanted to learn everything, open every toy to learn it's make-up. Still, I love learning about complex topics like astrophysics, quantum computing, etc. I also enjoy a good challenge these two factors together plus my eternal love for computers made this an easy choice.
                </p>
                

                <h3>My Education</h3>
                <p>
                I studied Computer programming at <strong>Seneca</strong> and received an <strong>Advanced Diploma</strong> in <strong>Computer programming and analysis</strong>. There I learned to work in an agile or scrum environment, trained in multiple languages including C++, Java, Javascript. I was thought the sentence "Git Good at Git", The logic behind the leyers of internet, the basics of networking, the importance of writing efficient code using <strong>Data Strutures/Dynamic Programming</strong>, using SQL and NoSQL databases...
                </p>
                <h3>My Personal life</h3>
                <p>
                I learn on a daily basis, whether it is a new language, algorithms or skills, I take challenges and am not afraid to fail. check my GitHub for the projects that I have created in order to learn and challenge myself including this website.
                When I'm not behind my computer, I play and have played competitive badminton for a long time, Also walking is my main way of exercising since I can listen to audio books while I do so. I'm a CMAS certified Diver I also watch tv shows and documentaries when i have time.
                </p>

            </div>
        
        
    )
}

export default Summery;

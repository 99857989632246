import { Component } from "react";
import ScrollTo from "./ScrollTo";

export default class Footer extends Component{

    
    render() {
        return(
            <div className = "footer ">
                
                    
                        
                            
                            <ScrollTo location={0} text="Back To Top"/>
                        
                        
                </div>
                    
                
        
        )
}
}

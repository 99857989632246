import React from 'react';


import NavBar from './NavBar'
import Footer from './footer';
import Body from './Body';


export default function Main() {
  return (
  <div className="index">
    
    <NavBar/> 
    <Body/>
    <Footer/>
    
  </div>);
}




